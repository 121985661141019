import { render, staticRenderFns } from "./SubmitProposalForm.vue?vue&type=template&id=51e4b7cc&scoped=true&v-if=proposalType%20%3D%3D%3D%201&"
import script from "./SubmitProposalForm.vue?vue&type=script&lang=js&"
export * from "./SubmitProposalForm.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "51e4b7cc",
  null
  
)

export default component.exports