<template v-if="proposalType === 1">
  <div>
    <b-form-row class="px-5">
      <b-col class="pt-3">
        <div class="caption">New governance contract address</div>
        <b-input placeholder="Enter address" v-model="form.address" trim></b-input>
      </b-col>
    </b-form-row>
    <b-form-row class="px-5">
      <b-col class="pt-3" md="auto">
        <TextBlock label="Voting ends"
                   tooltip="Date and time when the voting will be finished"
                   :amount="form.endDate"
                   :noParse="true"
        ></TextBlock>
      </b-col>
      <b-col class="pt-3" md="auto">
        <TextBlock label="Tokens to deposit"
                   tooltip="This amount of tokens will be temporarily deposited until the voting ends"
                   :amount="form.amount.toString()"
                   append="LQF"
        ></TextBlock>
      </b-col>
    </b-form-row>
  </div>


</template>

<script>
import moment from "moment";

export default {
  name: "SubmitProposalForm",
  components: {
    TextBlock: () => import("@/components/form/TextBlock"),
  },
  props: {
    proposal_type: Number,
  },
  data: function (){
    return {
      form: {
        address: '',
        endDate: moment(new Date(Date.now() + 2*24*60*60*1000)).format('DD/MM/YYYY HH:mm'),
        amount: 20000
      },
      conData: {}
    }
  },
  created() {
    this.conData.title = 'Confirm proposal'
    this.conData.subtitle = 'New governance contract address'
    this.conData.pool = {
      from: {
        title: 'You deposited',
        name: 'LQF',
        value: this.form.amount
      },
    }
    this.conData.listData = [
      {name: 'Voting ends', data: this.form.endDate, dataText: ''},
    ]
  }
}
</script>

<style scoped>

</style>
